import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VModal from "vue-js-modal";
import axios from "axios";
import globals from './components/globals';
import VueTelInput from "vue-tel-input";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import VeeValidate from "vee-validate";

Vue.prototype.$serverUrl = globals.CRM_SALES_URL;
Vue.prototype.$http = axios.create({
    baseURL: Vue.prototype.$serverUrl,
});
Vue.component("vue-tel-input", VueTelInput);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);

Vue.use(VModal);
Vue.use(VuePhoneNumberInput);
Vue.use(VueTelInput);
Vue.use(SweetModal);
Vue.use(SweetModalTab);
Vue.use(VeeValidate);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  components: {
    SweetModal,
    SweetModalTab,
  },
  data() {
    return {
      loggedIn: false,
    };
  },
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'

const routerOptions = [
  { path: '/', name: 'AuctionListView', componentPath: 'Auction/AuctionListView' },
]
const routes = routerOptions.map(route => {
  return {
      ...route,
      component: () => import(`@/components/${route.componentPath}.vue`)
  }
})

Vue.use(VueRouter)

export default new VueRouter({
  routes,
  base: process.env.BASE_URL,
  mode: 'history'
})
